import React from 'react'
import PropTypes from 'prop-types'
import {formatColorToHtml, textToValue} from "../helpers/formatVariables"
import {connect} from "react-redux"

class TableData extends React.Component {
    render() {
        return <>
            <table>
                <thead>
                    <tr>
                        {this.props.columns.map((column, i) =>
                            <td key={`col_${i}`} dangerouslySetInnerHTML={{__html: formatColorToHtml(column)}} />
                        )}
                    </tr>
                </thead>
                <tbody>
                    {this.props.rows.map((row, i) =>
                        <tr key={`row_${i}`}>
                            {row.map((field, j) => <td key={`field_${j}`} dangerouslySetInnerHTML={{__html: formatColorToHtml(textToValue(field, this.props.streamer))}}/>)}
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    }
}

TableData.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.string
        )
    ).isRequired,
}

const mapStateToProps = state => ({
    streamer: state.hystream.streamer,
})

export default connect(mapStateToProps)(TableData)
