import {hystream} from '../../../types'
import {handleRequest} from "../../../../api/plugins"
import api from "../../../api"

export function receiveStreamerStats(authData) {
    return {
        type: hystream.OVERLAY_FETCH_STATS,
        data: {
            ...authData,
            receivedAt: Date.now(),
        },
    }
}

export function fetchStreamer(uuid) {
    return dispatch => {
        try {
            handleRequest(api.hystream.fetchStreamer(uuid))
                .then(([err, result]) => {
                    if (err) {
                        return dispatch({type: hystream.OVERLAY_ERROR, err})
                    }

                    dispatch(receiveStreamerStats(result))
                })
        } catch (e) {
            dispatch({type: hystream.OVERLAY_ERROR, err: e.toString()})
        }
    }
}