/**
 * @param promises
 * @returns {Promise<*[]>}
 */
export const handleRequests = async (promises = []) => {
    let err = null
    let responses = null

    try {
        responses = await Promise.all(promises)
    } catch (e) {
        err = e
    }

    return [err, responses]
}

/**
 * @param promise
 * @returns {Promise<*[]>}
 */
export const handleRequest = async (promise) => {
    let [err, response] = await handleRequests([promise])

    if (Array.isArray(response) && response[0]) {
        response = response.shift()
    }

    return [err, response]
}
