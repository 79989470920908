import axios from 'axios'

// https://github.com/axios/axios#request-config
export const HyStream = axios.create(
    {
        baseURL: '/api/hystream',
        timeout: 60000,
        headers: {'X-Requested-With': 'XMLHttpRequest'},
        // `withCredentials` indicates whether or not cross-site Access-Control requests
        // should be made using credentials
        withCredentials: true
    }
)