import React from 'react'
import PropTypes from 'prop-types'
import {formatColorToHtml} from "../helpers/formatVariables"

const COLORS = {
    BLACK: '§0',
    DARK_BLUE: '§1',
    DARK_GREEN: '§2',
    DARK_AQUA: '§3',
    DARK_RED: '§4',
    DARK_PURPLE: '§5',
    GOLD: '§6',
    GRAY: '§7',
    DARK_GRAY: '§8',
    BLUE: '§9',
    GREEN: '§a',
    AQUA: '§b',
    RED: '§c',
    LIGHT_PURPLE: '§d',
    YELLOW: '§e',
    WHITE: '§f'
}

const RANKS = {
    NORMAL: {
        color: '§7',
        prefix: '§7'
    },
    VIP: {
        color: '§a',
        prefix: '§a[VIP] '
    },
    VIP_PLUS: {
        color: '§a',
        prefix: '§a[VIP§6+§a] '
    },
    MVP: {
        color: '§b',
        prefix: '§b[MVP] '
    },
    MVP_PLUS: {
        color: '§b',
        prefix: '§b[MVP$+§b] '
    },
    SUPERSTAR: {
        color: '§6',
        prefix: '@[MVP$++@] '
    },
    YOUTUBER: {
        color: '§c',
        prefix: '§c[§fYOUTUBE§c] '
    },
    JR_HELPER: {
        color: '§9',
        prefix: '§9[JR HELPER] '
    },
    HELPER: {
        color: '§9',
        prefix: '§9[HELPER] '
    },
    MODERATOR: {
        color: '§2',
        prefix: '§2[MOD] '
    },
    GAME_MASTER: {
        color: '§2',
        prefix: '§2[GM] '
    },
    ADMIN: {
        color: '§c',
        prefix: '§c[ADMIN] '
    }
}

// Pages
class PlayerName extends React.Component {
    get highestRankName() {
        const {
            rank,
            monthlyPackageRank,
            newPackageRank,
            packageRank
        } = this.props

        if (rank !== 'NORMAL') {
            return rank
        } else if (monthlyPackageRank !== 'NONE') {
            return monthlyPackageRank
        } else if (newPackageRank !== 'NONE') {
            return newPackageRank
        } else if (packageRank !== 'NONE') {
            return packageRank
        } else {
            return 'NORMAL'
        }
    }

    get highestRank() {
        const rankName = this.highestRankName

        return RANKS[rankName] || {
            color: '§7',
            prefix: `§7[${rankName}] `
        }
    }

    get formattedName() {
        const { displayName, prefix } = this.props

        if (prefix) {
            return `${prefix} ${displayName}`
        }

        const { monthlyRankColor, rankPlusColor } = this.props
        const rankPrefix = this.highestRank.prefix
            .replace(/@/g, COLORS[monthlyRankColor])
            .replace(/\$/g, COLORS[rankPlusColor])

        return formatColorToHtml(rankPrefix + displayName)
    }

    render() {
        return (
            <>
                {this.props.prefixText &&
                    <span dangerouslySetInnerHTML={{__html: formatColorToHtml(this.props.prefixText)}} />
                }
                <span dangerouslySetInnerHTML={{__html: this.formattedName}} />
            </>
        )
    }
}

PlayerName.propTypes = {
    prefixText: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    rank: PropTypes.string.isRequired,
    monthlyPackageRank: PropTypes.string.isRequired,
    newPackageRank: PropTypes.string.isRequired,
    packageRank: PropTypes.string.isRequired,
    monthlyRankColor: PropTypes.string.isRequired,
    rankPlusColor: PropTypes.string.isRequired,
}

export default PlayerName
