import {HyStream as http} from '../../../../api/client'

const fetchStreamer = async (uuid) => {
    try {
        const response = await http.get(`/overlay/${uuid}`)
        return response.data
    } catch (e) {
        return Promise.reject(e)
    }
}

const endpoints = {
    fetchStreamer,
}

/**
 * Auth API Endpoints
 */
export default endpoints