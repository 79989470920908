import React from 'react'
import {Route, BrowserRouter as Router, Switch} from "react-router-dom"

import "./scss/overlay/main.scss"

// Pages
import Overlay from "./pages/Overlay"

import {applyMiddleware, compose, createStore} from "redux"
import thunkMiddleware from "redux-thunk"
import {Provider} from "react-redux"
import rootReducer from './stores/reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(
            thunkMiddleware,
        )
    ),
)

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/:id" component={Overlay}/>
                </Switch>
            </Router>
        </Provider>
    )
}

export default App
