const config = [
    {
        type: "playerName",
        prefix: "§4[#{field:skywars.level}§6★§4] §7"
    },
    {
        type: "table",
        columns: [
            "",
            "",
        ],
        rows: [
            [
                "§aWins:",
                "§6#{field:skywars.global.wins}",
            ],
            [
                "§aKills:",
                "§6#{field:skywars.global.kills}",
            ],
            [
                "§aKDR:",
                "§6#{field:skywars.global.kdr}",
            ],
            [
                "§aWLR:",
                "§6#{field:skywars.global.wlr}",
            ],
            [
                "§aStream Wins:",
                "§6#{session:skywars.global.wins}",
            ],
            [
                "§aStream Kills:",
                "§6#{session:skywars.global.kills}",
            ],
            [
                "§aStream KDR:",
                "§6#{eval:(#{session:skywars.global.kills}/(#{session:skywars.global.deaths} || 1)).toFixed(2)}",
            ],
            [
                "§aStream WLR:",
                "§6#{eval:(#{session:skywars.global.wins}/(#{session:skywars.global.losses} || 1)).toFixed(2)}",
            ],
            [
                "§aWinstreak:",
                "§6#{field:skywars.win_streak}",
            ],
        ]
    },
]

export default config