import React from 'react'
import {
    withRouter,
} from 'react-router-dom'
import PlayerName from "../components/PlayerName"
import TableData from "../components/TableData"
import {formatColorToHtml, textToValue} from "../helpers/formatVariables"
import {connect} from "react-redux"
import * as hystreamActions from "../stores/actions/modules/hystream"
import config from "../helpers/config"

class Overlay extends React.Component {
    constructor(props) {
        super(props)

        this.interval = null
    }

    componentDidMount() {
        const {params: {id}} = this.props.match

        this.props.dispatch(hystreamActions.fetchStreamer(id))

        this.interval = setInterval(() => {
            this.props.dispatch(hystreamActions.fetchStreamer(id))
        }, 20 * 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    renderIsLoading() {
        return (
            <h3>Loading Stats</h3>
        )
    }

    render() {
        if (this.props.isLoading) {
            return this.renderIsLoading()
        }

        const state = this.props.streamer

        return (
            <div>
                {config.map((row, i) => {
                    if (row.type === "playerName") {
                        return <PlayerName
                            key={`row_${i}`}
                            prefixText={textToValue(row.prefix, state)}
                            displayName={state.name}
                            {...state.rank}
                        />
                    }

                    if (row.type === "table") {
                        return <TableData
                            key={`row_${i}`}
                            columns={row.columns}
                            rows={row.rows}
                        />
                    }

                    if (row.type === "text") {
                        return <p key={`row_${i}`} dangerouslySetInnerHTML={{__html: formatColorToHtml(textToValue(row.value, state))}}/>
                    }

                    return null
                })}

            </div>
        )
    }
}

const mapStateToProps = state => ({
    streamer: state.hystream.streamer,
    isLoading: state.hystream.isLoading,
    receivedAt: state.hystream.receivedAt,
})

export default connect(mapStateToProps)(withRouter(Overlay))
