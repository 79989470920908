import {hystream as actions} from '../../../types'

const initialStore = {
    streamer: {},
    receivedAt: null,
    isLoading: true,
}

const hystream = (state = initialStore, action) => {
    switch (action.type) {
        case actions.OVERLAY_FETCH_STATS:
            return {
                ...state,
                ...action.data,
                isLoading: false,
            }
        default:
            return state
    }
}

export default hystream
