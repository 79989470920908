import safeEval from "safe-eval"

const SESSION_START_VARIABLES = {}

export const COLORS_TO_CLASS = {
    '0': 'text-black',
    '1': 'text-darkblue',
    '2': 'text-darkgreen',
    '3': 'text-darkaqua',
    '4': 'text-darkred',
    '5': 'text-darkpurple',
    '6': 'text-gold',
    '7': 'text-gray',
    '8': 'text-darkgray',
    '9': 'text-blue',
    'a': 'text-green',
    'b': 'text-aqua',
    'c': 'text-red',
    'd': 'text-lightpurple',
    'e': 'text-yellow',
    'f': 'text-white'
}

export function formatColorToHtml(text) {
    if (text.length === 0) {
        return ''
    }

    if (!text.startsWith('§')) {
        text = `§7${text}`
    }

    let color = COLORS_TO_CLASS['7']
    const parts = text.split('§').slice(1)

    let output = ``
    for (const part of parts) {
        const key = part.charAt(0)
        color = COLORS_TO_CLASS[key] || color

        output += `<span class="${color}">${part.substring(1)}</span>`
    }

    return output
}

export function extractFormatters(textInput) {
    const args = []
    const commandStarts = []
    let nested = 0

    for (let i = 0; i < textInput.length; i++) {
        if (textInput[i] === '#' && textInput[i+1] === '{' && textInput[i-1] !== '\\') {
            nested++
            commandStarts.push(i)
            continue
        }

        if (textInput[i] === '}' && commandStarts.length > 0) {
            const start = commandStarts.pop()
            const text = textInput.substring(start, i + 1)
            const plainText = text.substring(2, text.length - 1).split(':')

            args.push({
                type: plainText.shift(),
                variable: plainText.join(':'),
                nested,
                start: start,
                end: i
            })

            nested--
        }
    }

    args.sort((a, b) => b.nested - a.nested || b.end - a.end)

    return args
}

export function textToValue(textInput, state) {
    let args = extractFormatters(textInput)

    if (args.length === 0) {
        return textInput.replace(/ /g, '&nbsp;')
    }

    for (let i = 0; i < args.length; i++) {
        const argument = args[i]
        const variables = argument.variable.split('.')

        let value

        if (argument.type === "field" || argument.type === "session") {
            value = state
            for (let i = 0; i < variables.length; i++) {
                value = value[variables[i]]
            }
        }

        if (argument.type === "session") {
            let sessionVar = SESSION_START_VARIABLES[argument.variable]

            if (!sessionVar) {
                sessionVar = SESSION_START_VARIABLES[argument.variable] = value
            }

            value = value - sessionVar
        }

        if (argument.type === "eval") {
            value = safeEval(extractFormatters(textInput.substring(argument.start, argument.end))[0].variable)
        }

        textInput = textInput.substring(0, argument.start) + value + textInput.substring(argument.end + 1)

        args = args.map(arg => {
            if (arg.start < argument.start && arg.end > argument.end) {
                arg.end = (arg.end - argument.variable.length) + String(value).length
            }

            return arg
        })
    }

    return textInput.replace(/ /g, '&nbsp;')
}